import React from "react"
import ExpandableComponent from "../expandableComponent"

export default function footerModalContentEmergencyLeaks(props) {
  const currentYear = new Date().getFullYear()

  return (
    <section className="page-content-container emergency-leaks">
      <h2 className="footer-modal-title fs5">
        To Report a Pipeline Emergency Involving a Pecan Pipeline Facility
        <br />
        <a href="tel:1-866-899-2626" className="call-highlight">
          Call 1-866-899-2626
        </a>
      </h2>

      <div className="questions-container">
        <Question1 />
        <Question2 />
        <Question3 />
      </div>

      <div className="action-items fs2">
        <h4 className="block highlight7 bold">
          Emergency Action Procedures for Public Safety Officials
        </h4>
        <br />
        <p className="bold">
          Public safety officials know to take whatever steps are deemed
          necessary to safeguard the public in the event of a pipeline
          emergency. These suggestions are offered only as a guide:
        </p>
        <br />
        <ul>
          <li>
            {" "}
            • Secure the area around the leak to a safe distance. This could
            include evacuating people from homes, businesses, schools, and other
            locations, as well as erecting barricades to control access to the
            emergency site and similar precautions. Some pipeline emergencies
            may make going outdoors dangerous. In these circumstances sheltering
            in place may be the safest course of action.
          </li>
          <br />
          <li>
            • If the pipeline leak is not burning, take steps to prevent
            ignition. This could include prohibiting smoking, rerouting traffic,
            and shutting off the electricity and residential gas supply.
          </li>
          <br />
          <li>
            • If the pipeline is burning, try to prevent the spread of fire, but
            do not attempt to extinguish it. Burning petroleum products will not
            explode, but if the fire is extinguished, gas and vapor may collect
            and could explode when reignited by secondary fires.
          </li>
          <br />
          <li>
            • Contact the pipeline company as quickly as possible. Pipeline
            marker signs show the pipeline company’s name, emergency telephone
            number, and pipeline contents.
          </li>
          <br />
          <li>
            • Public safety personnel should not attempt to operate any of the
            valves on the pipeline. Improperly operating the pipeline valves
            could escalate the situation and cause other accidents to occur.
          </li>
        </ul>
      </div>

      <div className="action-items fs2">
        <h4 className="block highlight7 bold">
          Pipeline Operator’s Actions During an Emergency
        </h4>
        <br />
        <p>
          The pipeline operator will immediately dispatch personnel to the site
          to assist in the emergency response and provide information to public
          safety officials. They will also take the necessary operating actions
          such as starting and stopping pumps, stopping compressor stations,
          closing and opening valves, and similar steps to address the
          situation.
          <br />
          Using the Incident Command System (ICS), pipeline operators work with
          local emergency officials to establish a coordinated approach to
          dealing with an incident.
        </p>
      </div>
      <small className="eog-watermark fs0">
        © {currentYear} Pecan Pipeline Company. All rights reserved.
      </small>
    </section>
  )
}

function Question1(props) {
  return (
    <ExpandableComponent
      className="question question1"
      title={
        <h2 className="bold questions-title">Recognizing a pipeline leak...</h2>
      }
      description={
        <p className="fs2">
          Pipeline companies regularly inspect their rights-of-way using air,
          foot and/or vehicle patrols. Trained inspectors look for situations
          which could damage pipelines, such as construction activity, as wells
          as signs of gas/liquids leaks.
          <br />
          <br />
          Because of potential hazards it is important to be able to recognize a
          pipeline leak. A pipeline leak may display any of the following signs:
          <br />
          <br />
          <span className="bold highlight8 block">Look</span>
          • A pool of liquid on the ground near a pipeline
          <br />
          • A dense white cloud or fog over a pipeline
          <br />
          • Discolored vegetation surrounding the pipeline
          <br />
          • An unusual dry spot in an otherwise moist field
          <br />
          • Bubbling in a wet flooded area, river or creek
          <br />
          • An oily sheen appearing on water surfaces
          <br />
          • Frozen ground at the pipeline in warm weather
          <br />
          • Dirt blowing up from the ground
          <br />
          <br />
          <span className="bold highlight8 block">Listen</span>
          An unusual noise coming from the pipeline, such as a hissing or a
          roaring sound
          <br />
          <br />
          <span className="bold highlight8 block">Smell</span>
          An unusual odor or scent of gas or petroleum; natural gas is primarily
          odorless; however, a scent that smells like rotten eggs (mercaptan) is
          added to distribution pipelines which take natural gas to homes and
          businesses
        </p>
      }
    />
  )
}

function Question2(props) {
  return (
    <ExpandableComponent
      className="question question2"
      title={
        <h2 className="bold questions-title">
          What to do when a leak occurs...
        </h2>
      }
      description={
        <div className="fs2">
          <ol type="1">
            <li>1. Immediately leave the area, staying upwind if possible.</li>
            <li>
              2. Abandon any equipment being used in or near the suspected leak.
            </li>
            <li>
              3. From a safe location, call 911 or your local emergency response
              number and the pipeline company. Provide your name, phone number,
              description of the leak and its location.
            </li>
            <li>4. Warn others to stay away when possible.</li>
          </ol>
        </div>
      }
    />
  )
}

function Question3(props) {
  return (
    <ExpandableComponent
      className="question question3"
      title={
        <h2 className="bold questions-title">
          What NOT to do when a leak occurs...
        </h2>
      }
      description={
        <div className="fs2">
          {" "}
          <ol type="1">
            <li>
              1. Do not touch, breathe or make contact with the leaking product.
              Stay upwind if possible.
            </li>
            <li>
              2. Do not create an ignition source by lighting a match, starting
              an engine, using a telephone, turning light switches on or off or
              taking other actions that may create a spark.
            </li>
            <li>
              3. Do not attempt to extinguish any pipeline fire that may start.
            </li>
            <li>
              4. Do not drive into a leak or vapor cloud area. Automobile
              engines may ignite the vapors.
            </li>
            <li>5. Do not attempt to operate pipeline valves.</li>
          </ol>
        </div>
      }
    />
  )
}
