import React from "react"

export default function ColorCodeChart(props) {
  return (
    <div className="color-code-chart">
      <div className="ccc-title fs4">Color Code Chart</div>
      <div className="ccc-part1">
        <ColorCodeChartColor
          color="White"
          description="Proposed Excavation"
          squareColor="#FFFEFF"
        />

        <ColorCodeChartColor
          color="Pink"
          description="Temporary Survey Markings"
          squareColor="#E64E98"
        />

        <ColorCodeChartColor
          color="Red"
          description="Electric Power Lines, Cables, Conduit, & Lighting Cables"
          squareColor="#F13122"
        />

        <ColorCodeChartColor
          color="Yellow"
          description="Gas, Oil, Steam, Petroleum, or Gaseous Materials"
          squareColor="#FEED00"
        />
      </div>
      <div className="ccc-part2">
        <ColorCodeChartColor
          color="Orange"
          description="Communication, Alarm or Signal Lines, Cables, or Conduit"
          squareColor="#FA9626"
        />

        <ColorCodeChartColor
          color="Blue"
          description="Potable Water"
          squareColor="#0179CA"
        />

        <ColorCodeChartColor
          color="Purple"
          description="Reclaimed Water, Irrigation, and Slurry Lines"
          squareColor="#98248D"
        />

        <ColorCodeChartColor
          color="Green"
          description="Sewers and Drain Lines"
          squareColor="#089368"
        />
      </div>
    </div>
  )
}

function ColorCodeChartColor({
  color = "",
  description = "",
  squareColor = "",
}) {
  return (
    <div className="ccc-color">
      <div
        style={{ backgroundColor: squareColor || color }}
        className="color"
      ></div>
      <div className="color-description">
        <div className="bold">{color}</div>
        <p>{description}</p>
      </div>
    </div>
  )
}
