import React from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import triangleIcon from "../../static/triangle.svg"

export default function expandableComponent({
  title = "",
  description = "",
  className = "",
}) {
  return (
    <ExpansionPanel className={`expandable-component ${className}`}>
      <ExpansionPanelSummary
        expandIcon={<img src={triangleIcon} alt="triangle icon" />}
        aria-controls="panel1a-content"
      >
        {title}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{description}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
