import React from "react"
import FooterModelContendCallBefore from "./footerModelContendCallBefore"
import FooterModalContentEmergencyLeaks from "./footerModalContentEmergencyLeaks"

export default function FooterModal({
  isFooterModalVisible = false,
  hideModal = () => {
    alert("provide the `hideModal` function")
  },
  source = "",
}) {
  let content = null
  if (source === "CallBefore") {
    content = <FooterModelContendCallBefore />
  } else if (source === "EmergencyLeaks") {
    content = <FooterModalContentEmergencyLeaks />
  }

  return (
    <div className={`footer-modal ${isFooterModalVisible && "active"}`}>
      <span
        className="close-modal fbx-cc"
        onClick={hideModal}
        onKeyDown={hideModal}
        role="button"
        tabIndex={0}
      >
        &#43;
      </span>
      {content}
    </div>
  )
}
