import React, { useState } from "react"
import FooterModal from "./footerModal/footerModal"
import footerBackground from "../../static/footer-bg.png"

export default function Footer(props) {
  const currentYear = new Date().getFullYear()
  const [footerState, setFooteModalVisibility] = useState({
    modalIsVisible: false,
    openedFrom: "",
  })

  const hideModal = () => {
    setFooteModalVisibility({ modalIsVisible: false, openedFrom: "" })
  }

  const showFooterModalFromCallBefore = () => {
    setFooteModalVisibility({
      modalIsVisible: true,
      openedFrom: "CallBefore",
    })
  }

  const showFooterModalFromEmergencyLeaks = () => {
    setFooteModalVisibility({
      modalIsVisible: true,
      openedFrom: "EmergencyLeaks",
    })
  }

  return (
    <React.Fragment>
      <footer className="pp-footer fs1">
        <img
          className="footer-diagonal-bg"
          src={footerBackground}
          alt="Footer design element"
        />
        <section className="footer-column footer-emergency-number">
          <h3 className="highlight1 big-emergency-number fs3">
            <a href="tel:1-866-899-2626">1-866-899-2626</a>
          </h3>
          <p className="info-text">Pecan Pipeline Company Emergency Number</p>
        </section>

        <section className="footer-column footer-info-box">
          <div className="before-dig">
            <h3 className="info-title fs3">Call Before You Dig</h3>
            <p className="info-text">
              or excavate.{" "}
              <a href="tel:811" className="highlight2 bold">
                Dial 811
              </a>{" "}
              to reach your local One Call Center.{" "}
              <span
                tabIndex={0}
                role="button"
                onClick={showFooterModalFromCallBefore}
                onKeyDown={showFooterModalFromCallBefore}
                className="learn-more"
              >
                Learn more
              </span>
            </p>
          </div>

          <div className="emergency-leaks">
            <h3 className="info-title fs3">Emergency / Leaks</h3>
            <p className="info-text">
              <a href="tel:1-866-899-2626" className="highlight1 bold nowrap">
                Call 1-866-899-2626
              </a>{" "}
              to report a pipeline emergency involving Pecan Pipeline’s
              facilities.{" "}
              <span
                tabIndex={0}
                role="button"
                onClick={showFooterModalFromEmergencyLeaks}
                onKeyDown={showFooterModalFromEmergencyLeaks}
                className="learn-more"
              >
                Learn more
              </span>
            </p>
          </div>
        </section>

        <section className="footer-column footer-disclaimers">
          <p className="footer-disclaimer fs1">
            Pecan Pipeline Company, Pecan Pipeline (Wyoming) LLC, and Pecan
            Pipeline (North Dakota), Inc. are each wholly-owned subsidiaries of
            EOG Resources, Inc.
          </p>
          <small className="eog-watermark fs0">
            © {currentYear} Pecan Pipeline Company. All rights reserved.
          </small>
        </section>
      </footer>
      <FooterModal
        hideModal={hideModal}
        isFooterModalVisible={footerState.modalIsVisible}
        source={footerState.openedFrom}
      />
    </React.Fragment>
  )
}
