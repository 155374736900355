import React from "react"
import mainImageBg from "../../static/mi-bg-design.png"

export default function MainImage({ src = "", header = "" }) {
  return (
    <figure
      className="pp-main-image"
      style={{ backgroundImage: `url(${src})` }}
    >
      <h1 className="mi-header fs6">
        <img
          src={mainImageBg}
          alt="diagonal background"
          className="mi-diagonal-bg"
        ></img>
        {header}
        <div className="mi-header-gradient fs6"></div>
      </h1>
    </figure>
  )
}
