import React, { Fragment } from "react"
import NavBar from "./navbar"
import Footer from "./footer"
export default ({children}) => (
  <Fragment>
    <NavBar />
    {children}
    <Footer />
  </Fragment>
)
