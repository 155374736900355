import React, { useState } from "react"
import Logo from "../../static/pecan-pipeline-logo.svg"
import { Link } from "gatsby"

export default function NavBar(props) {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false)

  return (
    <div
      className={`pp-navbar fbx-cc fs1 ${
        isMobileDrawerOpen ? "mobile-open" : ""
      }`}
    >
      <div className="fbx-cc pp-nb-logo-container">
        <img src={Logo} alt="Logo" />
      </div>

      <ul className="pp-nb-link-container">
        <ListLink to="/" className="pp-nb-link fbx-cc">
          Gas Gathering and Processing Systems
        </ListLink>
        <ListLink to="/map" className="pp-nb-link fbx-cc">
          Maps
        </ListLink>
        <ListLink to="/industry-links" className="pp-nb-link fbx-cc">
          Industry Links
        </ListLink>
        <ListLink to="/contact" className="pp-nb-link fbx-cc">
          Contact us
        </ListLink>
      </ul>
      <button
        className={`hamburger-icon container ${
          isMobileDrawerOpen ? "change" : ""
        }`}
        onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}
      >
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </button>
    </div>
  )
}

const ListLink = ({ className = "", to = "", children }) => (
  <li className={className}>
    <Link activeClassName="nav-active-link" to={to}>
      {children}
    </Link>
  </li>
)
