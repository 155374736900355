import React from "react"
import Logo811 from "../../../static/footer-modal/811-logo.png"
import ExpandableComponent from "../expandableComponent"
import Question2Diagram from "../../../static/footer-modal/question2-diagram.png"
import ColorCodeChart from "../colorCodeChart"

export default function FooterModelContendCallBefore(props) {
  const currentYear = new Date().getFullYear()

  return (
    <section className="page-content-container call-before">
      <h2 className="footer-modal-title fs5">
        Call Before You Dig or Excavate,{" "}
        <a href="tel:811" className="dial-highlight">
          Dial 811
        </a>
      </h2>
      <h3 className="footer-modal-subheader fs4">
        to reach your local <span className="bold">One Call Center</span>
      </h3>
      <div className="logo-holder semi-container">
        <div className="part1">
          <img src={Logo811} alt="logo" />
        </div>
        <div className="part2 fs4">
          It’s <span className="highlight7 bold">FREE</span>. It’s{" "}
          <span className="highlight7 bold">Easy</span>. It’s{" "}
          <span className="italic">your </span>
          responsibility as the excavator. It’s the{" "}
          <span className="highlight7 bold">LAW</span>!
        </div>
      </div>
      <p className="fs2">
        Nationwide 811 and Call Before You Dig programs are designed to promote
        public safety and increase public awareness for those working around
        buried underground facilities. When you call 811, your call will be
        forwarded to your state’s One Call Center. Your center will process a
        “locate request,” record information about where you plan to dig and
        then notify each member Company of affected underground facilities of
        your planned activity. The facility owners will review the “locate
        request” and if a conflict exists, will mark the location of their
        buried lines, so you can safely avoid damaging them. This is a free
        service.
        <br />
        <br />
        So, whether you are planning a simple landscaping project, getting ready
        to build a fence or expect to begin major construction, call before you
        dig. Failure to do so is the leading cause of damage to buried
        pipelines.
      </p>

      <div className="notice-times-table">
        <div className="notice-times-table-header">
          <div className="th">State</div>
          <div className="th">Notice Required</div>
        </div>
        <div className="notice-times-table-body">
          <div className="td">North Dakota</div>
          <div className="td">2 working days</div>
          <div className="td">Texas</div>
          <div className="td">2 working days</div>
          <div className="td">Wyoming</div>
          <div className="td">2 working days</div>
        </div>
      </div>
      <div className="question questions-container">
        <Question1 />
        <Question2 />
        <Question3 />
        <Question4 />
        <Question5 />
        <Question6 />
        <Question7 />
      </div>

      <p className="fs2">
        <span className="w500">
          Pipeline company representatives also provide information to emergency
          officials and work with local police and fire departments in case of
          an emergency.
        </span>
        <br />
        <br />
        The nation’s infrastructures, including pipelines, are a matter of
        national security. If you witness suspicious activity on a pipeline
        right-of-way, please report it to the appropriate authorities as soon as
        possible, or you may call the pipeline operators’ numbers listed on the
        right-of-way markers. Threat advisories may be found at the Department
        of Homeland Security’s website{" "}
        <a
          className="highlight3 underline"
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.dhs.gov."
        >
          www.dhs.gov
        </a>
        .
      </p>

      <ColorCodeChart />
      <div className="semi-container text-center">
        Learn more by following the link to the National Excavator Initiative
        (NEI) website:{" "}
        <a
          className="highlight3 underline"
          href="https://www.safeexcavator.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.safeexcavator.com/
        </a>
        .
        <br />
        For additional information regarding Hawthorn Oil Transportation’s
        pipelines and facilities, please email
        <br />
        <a
          className="highlight3 underline"
          href="mailto:publicawareness@eogresources.com"
        >
          publicawareness@eogresources.com
        </a>
        .
        <br />
        <br />
        <span className="italic">
          Note: Please consult Texas or North Dakota One Call notification
          regulations for the latest requirements.
        </span>
      </div>
      <small className="eog-watermark fs0">
        © {currentYear} Pecan Pipeline Company. All rights reserved.
      </small>
    </section>
  )
}

function Question1(props) {
  return (
    <ExpandableComponent
      className="question question1"
      title={
        <h2 className="bold questions-title">
          How do I know if there is a pipleline nearby?
        </h2>
      }
      description={
        <div className="fs2">
          For your safety, pipeline markers show the approximate location of
          pipelines and identify the companies that own them. Markers may be
          anywhere within the right-of-way. They most likely are not directly
          over the pipeline itself.
          <br />
          They can also be found where pipelines intersect a street, highway or
          railway.
          <br />
          <br />
          <h3 className="bold">Pipeline markers indicate:</h3>
          <ul>
            <li>• The name of the company operating the pipeline</li>
            <li>• The product being transported</li>
            <li>• The company’s emergency phone number</li>
          </ul>
          <br />
          While markers are helpful in alerting you about the presence of nearby
          pipelines, they are limited in the information they provide. For
          example, they provide no information on the depth or number of
          pipelines in the right-of way.
          <br />
          <br />
          Take time to familiarize yourself with any pipeline markers in your
          neighborhood. It’s a good idea to write down the name and phone
          numbers appearing on the pipeline markers in case of an emergency.
          <br />
          <br />
          Because pipeline markers are important for the safety of the general
          public, it is a federal crime for any person to willfully deface,
          damage, remove, or destroy any pipeline sign or right-of-way marker.
        </div>
      }
    />
  )
}

function Question2(props) {
  return (
    <ExpandableComponent
      className="question question2"
      title={
        <h2 className="bold questions-title">
          What do pipeline markers look like?
        </h2>
      }
      description={
        <div className="fs2">
          <span className="bold">
            Pipeline markers come in a variety of shapes and colors. Below are
            some typical examples:
          </span>
          <br />
          <span className="bold highlight8">A.</span> Located near roads,
          railroads, and along the pipeline right-of-ways
          <br />
          <span className="bold highlight8">B.</span> Aerial Patrol Marker
          <br />
          <span className="bold highlight8">C.</span> Pipeline casing vent
          <br />
          <span className="bold highlight8">D.</span> Painted metal or plastic
          posts
          <br />
          <img
            className="question question2-diagram"
            src={Question2Diagram}
            alt="diagram"
          />
        </div>
      }
    />
  )
}

function Question3(props) {
  return (
    <ExpandableComponent
      className="question question3"
      title={
        <h2 className="bold questions-title">
          Can I build or dig on a right-of-way?
        </h2>
      }
      description={
        <p className="fs2">
          Pipeline rights-of-way must be kept free from structures and other
          obstructions to provide access for maintenance or emergency crews. If
          a pipeline crosses your property, please do not plant trees or high
          shrubs on the right-of-way. Do not dig, build, store or place anything
          on or near the right-of-way without first having pipeline company
          personnel mark the pipeline or stake the right-of-way and explain the
          company’s construction guidelines to you.
        </p>
      }
    />
  )
}

function Question4(props) {
  return (
    <ExpandableComponent
      className="question question4"
      title={
        <h2 className="bold questions-title">
          What do I do if I accidently damage a pipeline?
        </h2>
      }
      description={
        <p className="fs2">
          The law requires you to report all damages to the pipeline operator
          immediately. This includes any scrapes, gouges, dents, etc. regardless
          of size to avoid the potential of a future leak or rupture. Company
          representatives will inspect the line and take appropriate action. Do
          not attempt to make the repairs to the pipeline yourself.
        </p>
      }
    />
  )
}

function Question5(props) {
  return (
    <ExpandableComponent
      className="question question5"
      title={
        <h2 className="bold questions-title">
          Where can I get more information?
        </h2>
      }
      description={
        <p className="fs2">
          Pecan Pipeline is proud to be a member of the local One Call systems
          in the states where we operate.
          <br />
          <span className="bold">Visit these websites to learn more:</span>
          <br />• In Texas:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="highlight3 underline"
            href="http://www.texas811.org"
          >
            www.texas811.org
          </a>
          <br />• In North Dakota:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="highlight3 underline"
            href="http://www.ndonecall.com"
          >
            www.ndonecall.com
          </a>
        </p>
      }
    />
  )
}

function Question6(props) {
  return (
    <ExpandableComponent
      className="question question6"
      title={
        <h2 className="bold questions-title">
          Why are pipelines known as "Your Quiet Neighbor?"
        </h2>
      }
      description={
        <p className="fs2">
          The United States has the largest pipeline network in the world.
          Pipelines are an essential part of our nation’s infrastructure,
          delivering natural gas and liquid petroleum products that power our
          lives. According to government studies, pipelines are the safest and
          most reliable way to transport these essential energy products.
          <br />
          <br />
          Pecan Pipeline owns and operates natural gas gathering pipelines in
          Texas and North Dakota. The lines are subject to many government
          regulations as well as industry operating standards outlining safe
          operating practices. These regulations and standards deal with all
          phases of pipeline operations.
          <br />
          <br />
          To find out more visit:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="highlight3 underline"
            href="http://primis.phmsa.dot.gov/comm/Index.htm"
          >
            http://primis.phmsa.dot.gov/comm/Index.htm
          </a>
        </p>
      }
    />
  )
}

function Question7(props) {
  return (
    <ExpandableComponent
      className="question question7"
      title={
        <h2 className="bold questions-title">
          What steps do companies take to keep the pipelines safe?
        </h2>
      }
      description={
        <p className="fs2">
          <span className="w500">
            To maintain safe, reliable operations of pipelines and facilities,
            operators deploy many preventative measures and procedures such as:
          </span>
          <br />
          <br />
          • Preventative maintenance programs
          <br />
          • Pipeline monitoring
          <br />
          • Aerial surveys
          <br />
          • Ground surveys
          <br />
          • Cathodic protection to inhibit corrosion
          <br />
          <br />
          Pipeline operators have Integrity Management Plans which provide a
          process for monitoring, managing and mitigating risks along the
          pipeline system. In addition, natural gas and liquid pipeline
          operators have created a High Consequence Area (HCA) plan for
          environmentally sensitive areas, urbanized and populated areas,
          impaired mobility areas and navigable waterways. For more information
          on these plans, email{" "}
          <a
            className="highlight3 underline"
            href="mailto:publicawareness@eogresources.com"
          >
            publicawareness@eogresources.com
          </a>
          .
        </p>
      }
    />
  )
}
